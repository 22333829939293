<template>
	<div class="lithium-battery">
		<div class="header acea-row row-between-wrapper" ref="header">
			<div class="black" @click="returnChange()">
				<img src="@assets/images/icon_black.png" alt="" />
			</div>
			<p>锂电芯打样</p>
		</div>
		<div class="lithium-content">
			<div class="lithium-tips">
				<p>温馨提示：1.请填写以下项目，带*号为必填项; 提交后，我们会有专人与您联系，请保持电话畅通！2.如填写遇到困难，可联系人工客服协助填写！</p>
			</div>
			<div class="lithium-item acea-row row-between">
				<div class="item">
					<p><span>*</span>电芯材料</p>
					<div class='content'>
						<CellGroup>
							<CellItem arrow type="label" icon>
								<select slot="right" v-model="param.material_type" @change="selectCompanyBrandChange($event,1)">
									<option :value="index" v-for="(item,index) in selectData.material_list"
										:key="index">{{item}}</option>
								</select>
							</CellItem>
						</CellGroup>
					</div>
				</div>
				<div class="item">
					<p><span>*</span>电池封装</p>
					<div class='content'>
						<CellGroup>
							<CellItem arrow type="label">
								<select slot="right" v-model="param.package_type" @change="selectCompanyBrandChange($event,1)">
									<option :value="index" v-for="(item,index) in selectData.package_list" :key="index">
										{{item}}</option>
								</select>
							</CellItem>
						</CellGroup>
					</div>
				</div>
			</div>
			<div class="lithium-item">
				<p><span>*</span>公司分类</p>
				<div class='content'>
					<CellGroup>
						<CellItem arrow type="label">
							<select slot="right" v-model="param.company_brand"
								@change="selectCompanyBrandChange($event,2)">
								<option value="">请选择公司分类</option>
								<option :value="index" v-for="(item,index) in selectData.company_list" :key="index">
									{{item}}</option>
							</select>
						</CellItem>
					</CellGroup>
				</div>
			</div>
			<div class="lithium-item">
				<p><span>*</span>企业名称</p>
				<div class='content'>
					<CellGroup>
						<CellItem arrow type="label">
							<select slot="right" v-model="param.company_name" @change="selectCompanyNameChange($event)">
								<option value="">请选择企业名称</option>
								<option :value="item.id" v-for="(item,index) in selectData.companyNameList"
									:key="index">{{item.name}}</option>
							</select>
						</CellItem>
					</CellGroup>
				</div>
			</div>
			<div class="lithium-item" v-if="remark" style="margin-top: -20px;">
				<p>备注：{{remark}}</p>
			</div>
			<div class="lithium-item">
				<p><span>*</span>电芯形状</p>
				<div class='content acea-row row-between'>
					<CellGroup>
						<CellItem arrow type="label">
							<select slot="right" v-model="param.shape_type">
								<option value="">请选择电芯形状</option>
								<option :value="index" v-for="(item,index) in selectData.shape_list" :key="index">
									{{item}}</option>
							</select>
						</CellItem>
					</CellGroup>
				</div>
			</div>
			<div class="lithium-item">
				<p><span>*</span>放电倍率</p>
				<div class='content'>
					<CellGroup>
						<CellItem arrow type="label">
							<select slot="right" v-model="param.discharge_rate" @blur="selectChange()">
								<option value="">请选择放电倍率</option>
								<option :value="parseInt(index)" v-for="(item,index) in selectData.discharge_rate_list" :key="index">{{item}}</option>
							</select>
						</CellItem>
					</CellGroup>
				</div>
			</div>

			<!-- <div class="lithium-item">
				<p><span>*</span>期望打样周期（工作日）</p>
				<div class='content acea-row row-between'>
					<div class="other-detail fir acea-row row-between">
						<span class="magn"><input type="text" v-model="param.delivery_period" /></span>
					</div>
				</div>
			</div> -->

			<div class="lithium-item">
				<p><span>*</span>尺寸厚度<span>（mm）（2≤厚度≤30）</span></p>
				<div class='content'>
					<div class="other-detail fir acea-row row-between">
						<span class="magn"><input type="number" min="10" max="30" v-model="param.long" /></span>
					</div>
				</div>
			</div>

			<div class="lithium-item">
				<p><span>*</span>尺寸宽度<span>（mm）（10≤宽度≤200）</span></p>
				<div class='content'>
					<div class="other-detail fir acea-row row-between">
						<span class="magn"><input type="number" min="10" max="200" v-model="param.width" /></span>
					</div>
				</div>
			</div>

			<div class="lithium-item">
				<p><span>*</span>尺寸高度 <span>（mm）（10≤高度≤300）</span></p>
				<div class='content'>
					<div class="other-detail fir acea-row row-between">
						<span class="magn"><input type="number" min="10" max="300" v-model="param.height" /></span>
					</div>
				</div>
			</div>
		</div>
		<div class="detail-btn acea-row row-between" @click="nextChange()">
			<div class="right sub-success bat">
				<p>下一步</p>
			</div>
		</div>
		<div class="dis-contact" @click="getServiceAuto()">
			<!-- <router-link to="/customer/list"> -->
			<img src="@assets/images/icon_contast1.png" alt="">
			<!-- <span></span> -->
			<!-- </router-link> -->
		</div>

		<batteryPopu v-on:changeFun2="changeFun2" :storeInfo="paramPrice" :attr="attr" :typename="'product'">
		</batteryPopu>

	</div>
</template>
<script type="text/javascript">
	import {
		CellGroup,
		CellItem
	} from 'vue-ydui/dist/lib.rem/cell';
	// import {Spinner} from 'vue-ydui/dist/lib.rem/spinner';
	import {
		getCalc,
		confirmProofing,
		getProofingCompanyTypeData,
		getProofingParamsByCompanyBrand,
		getProofingParamsByCompanyId
	} from "@api/public";
	import {
		serviceAuto
	} from "@api/user";
	// in_number
	import {
		Verify_Empty,
		in_number,
		in_num
	} from '@utils/dy_validate';
	import batteryPopu from "@components/batteryPopu";
	export default {
		name: 'lithiumStepBattery',
		components: {
			CellGroup,
			CellItem,
			// Spinner,
			batteryPopu
		},
		data() {
			return {
				type: '',
				showNum: false,
				selectData: {
					companyNameList: []
				}, //下拉选项数据
				proofing_fixed_cost: 0, //固定打样费
				materialType: [],
				param: {
					company_brand: '', // 公司品牌
					company_name: '', // 公司名称
					package_type: 1, //电池封装
					material_type: 1, //电池材料
					discharge_rate: '', //放电倍率
					long: '', //长度
					width: '', //宽度
					height: '', //高度
					shape_type: '', //电芯形状
					name: ''
				},
				paramPrice: {
					capacity: 0, // 容量
					sample_price: 0, //样品价格
					total_price: 0, // 总价格
					price: 0, //单价
				},
				attr: {
					cartAttr: false,
					productAttr: [],
					productSelect: {}
				},
				remark: ''
			}
		},
		watch: {
			$route(n) {
				if (n.name === "lithiumStepBattery") {
					this.type = this.$route.query.type;
					if (this.$route.query.name == "1") {
						this.param.name = this.$route.query.name;
					} else {
						this.param.name = '';
					}
					this.getSelectData();
				}
			}
		},
		mounted() {
			this.type = this.$route.query.type;
			if (this.$route.query.name == "1") {
				this.param.name = this.$route.query.name;
			} else {
				this.param.name = '';
			}
			this.getSelectData();
			// this.getPriceData();
		},
		destroyed() {
			// console.log(2222)
		},
		methods: {
			//进入客服
			getServiceAuto() {
				serviceAuto().then(res => {
					console.log(res);
					location.href = "https://work.weixin.qq.com/kfid/kfce5fb4acd32b5c3d5";
					// this.$router.push(
					//   '/customer/chat/' +
					//     res.data.to_uid
					//     // +
					//     // '/' +
					//     // productId +
					//     // (orderId ? '?orderId=' + orderId : '')
					// )
				});
			},
			//点击下一步
			nextChange() {
				var that = this;
				console.log(that.param)
				if (that.validateForm()) {
					this.$router.push({
						path: "/lithium_battery?data=" + JSON.stringify(that.param)
					});
				}
			},
			//验证表单参数
			validateForm() {
				if (Verify_Empty(this.param.company_brand)) {
					this.$dialog.toast({
						mes: '请选择公司分类'
					});
				} else if (Verify_Empty(this.param.company_name)) {
					this.$dialog.toast({
						mes: '请选择公司名称'
					});
				} else if (Verify_Empty(this.param.package_type)) {
					this.$dialog.toast({
						mes: "请选择电池封装"
					});
					return false;
				} else if (Verify_Empty(this.param.material_type)) {
					this.$dialog.toast({
						mes: "请选择电池材料"
					});
					return false;
				} else if (Verify_Empty(this.param.discharge_rate)) {
					this.$dialog.toast({
						mes: "请选择电池放电倍率"
					});
					return false;
				} else if (Verify_Empty(this.param.shape_type)) {
					this.$dialog.toast({
						mes: "请选择电芯形状"
					});
					return false;
				} else if (Verify_Empty(this.param.long)) {
					this.$dialog.toast({
						mes: "请输入厚度"
					});
					return false;
				} else if (!in_num(this.param.long) && !in_number(parseFloat(this.param.long))) {
					this.$dialog.toast({
						mes: "厚度必须是整数或者最多保留一位小数"
					});
					return false;
				} else if (Verify_Empty(this.param.width)) {
					this.$dialog.toast({
						mes: "请输入宽度"
					});
					return false;
				} else if (!in_num(this.param.width)) {
					this.$dialog.toast({
						mes: "宽度必须为整数"
					});
					return false;
				} else if (Verify_Empty(this.param.height)) {
					this.$dialog.toast({
						mes: "请输入高度"
					});
					return false;
				} else if (!in_num(this.param.height)) {
					this.$dialog.toast({
						mes: "高度必须为整数"
					});
					return false;
				} else {
					return true;
				}
			},

			//点击立即购买
			joinCart: function() {
				//打开加入购物车弹窗
				let that = this;
				if (this.validateForm()) {
					that.attr.cartAttr = true;
				}
			},
			changeFun2: function(opt) {
				let that = this;
				that.attr.cartAttr = opt.value;
				if (opt.index == 1) {
					this.save();
				}
			},
			//选择触发事件
			selectCompanyBrandChange(event,k) {
				console.log(this.param)
				// if(this.param.package_type && this.param.material_type && this.param.discharge_rate && this.param.shape_type && this.param.delivery_period){
				// 	this.showNum = true;
				// } else {
				// 	this.showNum = false;
				// }
				this.param.company_name = ''
				this.param.shape_type = ''
				this.param.discharge_rate = ''
				this.selectData.discharge_rate_list=''
				this.selectData.shape_list=''
				this.remark = ''
				var data = this.param;
				if (k==2){
					data.company_brand = event.target.value
				}
				getProofingParamsByCompanyBrand(data).then(res => {
						this.selectData = {
							...this.selectData,
							companyNameList: res.data.company
						};
					})
					.catch(res => {
						this.$dialog.error(res.msg);
					})
				// if (this.param.company_brand && this.param.company_name) {
				// 	this.getPriceData()
				// }
			},
			//数据提交
			save() {
				if (this.validateForm()) {
					var data = {
						company_name: this.param.company_name,
						company_brand: this.param.company_brand,
						package_type: this.param.package_type,
						material_type: this.param.material_type,
						discharge_rate: this.param.discharge_rate,
						shape_type: this.param.shape_type,
						long: this.param.long,
						width: this.param.width,
						height: this.param.height,
						num: this.param.num,
					};
					confirmProofing(data)
						.then(res => {
							this.$dialog.success(res.msg).then(() => {
								res.data.type = this.type;
								this.$router.push({
									path: "/order/proofingsubmit?data=" + JSON.stringify(res)
								});
							});
						}).catch(res => {
							this.$dialog.error(res.msg);
						})
				}
			},
			/**
			 * 切换公司id，查询对应的参数
			 */
			selectCompanyNameChange(event) {
				getProofingParamsByCompanyId(event.target.value).then(res => {
						this.selectData = {
							...this.selectData,
							...res.data,
							material_list: this.selectData.material_list,
							package_list: this.selectData.package_list
						};
						this.remark = res.data.remark;
						console.log("A select" + JSON.stringify(res.data));
					})
					.catch(res => {
						this.$dialog.error(res.msg);
					})
			},

			//数量增减回调
			getNumData() {
				this.getNumChange();
			},
			//选择触发事件
			selectChange() {
				if (this.param.package_type && this.param.material_type && this.param.discharge_rate && this.param
					.shape_type && this.param.delivery_period) {
					this.showNum = true;
				} else {
					this.showNum = false;
				}
			},
			//尺寸触发事件
			getNumChange() {
				if (this.param.long && this.param.width && this.param.height && this.param.delivery_period) {
					// this.getPriceData();
				}
			},
			//获取价格参数
			getPriceData() {
				var data = {
					company_name: this.param.company_name,
					company_brand: this.param.company_brand,
					package_type: this.param.package_type,
					material_type: this.param.material_type,
					discharge_rate: this.param.discharge_rate,
					shape_type: this.param.shape_type,
					long: this.param.long,
					width: this.param.width,
					height: this.param.height,
					delivery_period: this.param.delivery_period,
					num: this.param.num,
				};
				getCalc(data)
					.then(res => {
						if (Array.isArray(res.data) && res.data.length === 0) {
							this.$dialog.toast({
								mes: '该打样配置不存在,请重新选择'
							});
							return
						}
						var l, w, h;
						if (parseInt(this.param.long) < 10) {
							l = '0' + this.param.long.toString();
						} else {
							l = this.param.long.toString();
						}
						if (parseInt(this.param.width) < 10) {
							w = '0' + this.param.width.toString();
						} else {
							w = this.param.width.toString();
						}
						if (parseInt(this.param.height) < 10) {
							h = '0' + this.param.height.toString();
						} else {
							h = this.param.height.toString();
						}
						res.data.xinghao = l + w + h;
						this.paramPrice = res.data;
						this.paramPrice.num = this.param.num;
						this.paramPrice.proofing_fixed_cost = this.proofing_fixed_cost;
					})
					.catch(res => {
						this.$dialog.error(res.msg);
					})
			},
			//获取下拉选项参数
			getSelectData() {
				// getProofingCompanyData()
				getProofingCompanyTypeData()
					.then(res => {
						this.selectData = {
							...this.selectData,
							// companyNameList: res.data.company, // todo
							material_list: res.data.material_type,
							package_list: res.data.package_type,
							company_list: res.data.company_brand,
						};
						// this.proofing_fixed_cost = res.data.proofing_fixed_cost;
					})
					.catch(res => {
						this.$dialog.error(res.msg);
					});
			},

			//返回上一页
			returnChange() {
				var that = this;
				that.$router.go(-1);
			},
		}
	}
</script>
<style type="text/css">
	.lithium-battery {
		padding-top: 50px;
	}

	.lithium-battery.router {
		background: #fff
	}

	.lithium-battery .header {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 0.96rem;
		font-size: 0.3rem;
		color: #050505;
		background-color: #fff;
		z-index: 11;
		border-bottom: 0.01rem solid #eee;
	}

	.lithium-battery .header .black {
		width: 7px;
		height: 14px;
		margin-left: 10px;
	}

	.lithium-battery .header .black img {
		width: 100%;
		height: 100%;
	}

	.lithium-battery .header p {
		width: calc(100% - 17px);
		text-align: center;
		color: #333;
	}
</style>