<template>
  <div v-if="typename === 'product'">
    <div
      class="product-window group-con"
      :class="attr.cartAttr === true ? 'on' : ''"
    >
      <div class="textpic lithium">
        <div class="tit acea-row row-between">
          <p>打样详情</p>
        </div>
        <div class="popu-content  acea-row row-between">

          <div class="popu-lithium leftt">
            <p>容量：{{storeInfo.capacity}} mAh</p>
            <p>型号：{{storeInfo.xinghao}}</p>
            <p>数量：{{storeInfo.num}}</p>
          </div>
          <div class="popu-lithium rightt">
            <p class="acea-row row-between"><span>单价（元/Ah）：</span> <span class='price'>￥ {{storeInfo.price}}</span></p>
            <p class="acea-row row-between"><span>样品价格：</span><span class='price'>￥ {{storeInfo.sample_price}}</span></p>
            <p class="acea-row row-between"><span>打样费：</span><span class='price'>￥ {{storeInfo.proofing_fixed_cost}}</span></p>
          </div>
        </div>
        <div class="iconfont icon-guanbi" @click="closeAttr"></div>
      </div>
      <div class="detail-btn acea-row row-between" style="z-index:100">
        <div class="left acea-row row-left">
          <p class="amount">
            <span>金额：￥{{ storeInfo.total_price }}</span>
          </p>
        </div>
        <div
          class="right"
          :class="firstJum == true ? 'grey' : ''"
          @click="cartAddChange()"
        >
          <p>确定</p>
        </div>
      </div>
    </div>
    <div
      class="mask"
      :hidden="attr.cartAttr === false"
      @click="closeAttr"
    ></div>
  </div>
</template>
<script>
import { changeCartNum, getProductsNum } from "@api/store";

export default {
  name: "ProductPopu",
  props: {
    attr: {
      type: Object,
      default: () => {}
    },
    storeInfo: {
      type: Object,
      default: () => {}
    },
    typename: {
      type: String,
      default: () => {}
    }
  },
  data: function() {
    return {
      proNum: 1,
      price: "",
      firstJum: false
    };
  },
  mounted: function() {
    var that = this;
    if (that.typename == "product") {
      that.proNum = 1;
    } else {
      that.proNum = that.storeInfo.cart_num;
    }
  },
  methods: {
    bindInputChange:function(e) {
      var that = this;
      if(e.data==''||e.data=='undefined'||e.data==null||e.data==0||parseFloat(e.data)<0){
        e.data = 1;
        that.proNum = 1;
        that.pricesChange(e.data);
      } else {
        that.pricesChange(e.data);
      }

    },
    CartNumDes: function() {
      let that = this;
      that.proNum -= 1;
      if (that.proNum <= 0) {
        that.proNum = 1;
        this.$dialog.toast({ mes: "数量不能小于0" });
        return false;
      }
      if (that.typename == "product") {
        that.pricesChange(that.proNum);
      }
      // else {
      // that.pricesCartChange(that.proNum)
      // }
    },
    CartNumAdd: function() {
      let that = this;
      that.proNum += 1;
      if (that.typename == "product") {
        if (that.proNum >= that.storeInfo.stock) {
          that.proNum = that.storeInfo.stock;
          this.$dialog.toast({ mes: "数量大于库存" });
        }
        that.pricesChange(that.proNum);
      } else {
        if (that.proNum >= that.storeInfo.productInfo.stock) {
          that.proNum = that.storeInfo.productInfo.stock;
          this.$dialog.toast({ mes: "数量大于库存" });
        }
        // that.pricesCartChange(that.proNum)
      }
    },
    //商品修改数量后，对应的价格变化
    pricesChange(index) {
      var that = this;
      getProductsNum(that.storeInfo.id, index)
        .then(res => {
          that.price = res.data.price;
        })
        .catch(res => {
          console.log(res);
        });
    },
    //购物车修改数量后，对应的价格变化
    pricesCartChange() {
      var that = this;
      changeCartNum(that.storeInfo.id, that.proNum)
        .then(res => {
          that.price = res.data.price;
          that.$parent.getCartList();
        })
        .catch(res => {
          console.log(res);
        });
    },
    //添加购物车
    cartAddChange: function() {
      let that = this;
        that.$emit("changeFun2", {
          action: "ChangeCartNum",
          value: false,
          index: 1
        });
    },
    closeAttr: function() {
      this.$emit("changeFun2", {
        action: "ChangeCartNum",
        value: false,
        index: 2
      });
    }
  }
};
</script>
<style scoped>
.joinCart {
  width: 80%;
  height: 0.76rem;
  line-height: 0.76rem;
  text-align: center;
}
.ipt_num {
  width: 100%;
  display: block;
  line-height: 0.54rem;
  text-align: center;
}

.detail-btn {
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  box-shadow: 0px -2px 5px #eaeaea;
  background: #fff;
}
.detail-btn.shop-car {
  bottom: 50px;
}
.detail-btn .left {
  width: 50%;
  height: 100%;
}
.detail-btn .left img {
  width: 21px;
  height: 18px;
}
.detail-btn .left p {
  font-size: 14px;
  color: #8f8f8f;
  line-height: 20px;
}
.detail-btn .left p.amount {
  line-height: 50px;
  margin-left: 10px;
}
.detail-btn .left p.amount span {
  color: #ff7900;
}
.detail-btn .left p.lit {
  font-size: 12px;
  padding: 2px 5px;
}
.detail-btn .left p.lit.tol {
  font-weight: 600;
}
.detail-btn .left p.lit span {
  color: #ff7a00;
}
.detail-btn .left.bat {
  background: #eee;
}
.detail-btn .right {
  width: 50%;
  height: 100%;
  background: #ff7900;
  text-align: center;
}
.detail-btn .right.pay {
  width: 100%;
}
.detail-btn .right.pay-done {
  width: 100%;
  background: #00a10c;
}
.detail-btn .right p {
  font-size: 14px;
  color: #fff;
  line-height: 50px;
}
.detail-btn .right.sub-success {
  width: 100%;
}
.detail-btn .right.bule {
  background: #0294e6;
}
.detail-btn .right.grey {
  background: #949697;
}
</style>
